import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'

import SeoHeader from '../components/SeoHeader'
import Hero from '../components/Hero'
import BookADemoHero from '../components/BookADemoHero'

export default function Index ({ data }) {
  return (
    <React.Fragment>
      <SeoHeader />
      <Hero
        imgSrc={data.imageOne}
        title="shamal the wind of change."
        subtitle="We are pioneers in the development of advanced work wear specially designed to combat extreme heat. Inspired by the dedication and resilience of the work force, we are committed to empowering workers through meaningful innovation, design and technology."
        trailingText="Reach out to us to witness the product in action."
        buttons={['demo', 'video']}
        full
      />
      <div className="breakup clearfix" />
      <Hero
        mainTitle="built for workers."
        imgSrc={data.imageTwo}
        subtitle="A company’s efficiency rests on the performance of its workers. We are bringing attention to the workers’ segment performing at the very edge of human endurance, where temperatures are often well above 40C. By offering a technical working gear for hot climates, we enhance comfort and improve productivity."
        trailingText="There is more behind Shamal."
        buttons={['story']}
        right
      />
      <div className="breakup clearfix" />
      <Hero
        mainTitle="engineered to combat extreme heat."
        imgSrc={data.imageThree}
        subtitle="Our commitment to workers is first realized in our innovative work wear. The product is a result of extensive research and testing to create a specialized garment that effectively deals with extreme heat. The product features latest fabric technologies designed by industry experts for heat dissipation and durability. By combining these effective capabilities into one suit, we developed an engineering marvel that regulates body temperature in the heat."
        trailingText="Shamal workwear is a game changer in its category and a reliable support for workers."
        buttons={['innovations']}
      />
      <div className="breakup clearfix" />
      <Hero
        mainTitle="CARING: ENSURING A HARDWORKING ECOSYSTEM"
        imgSrc={data.imageFour}
        subtitle="While its specialized work wear marks the first milestone, the future promises a full range of innovative gear and a complete ecosystem for the welfare of workers. The ecosystem is designed to support companies in implementing best practices in the industry, optimizing the impact of workers on businesses."
        trailingText="Shamal enables businesses to care for their workers."
        // buttons={['success']}
        right
      />
      <div className="breakup clearfix" />
      <BookADemoHero image={data.bookADemo} />
    </React.Fragment>
  )
}

Index.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    imageOne: file(relativePath: { eq: "img/_AU_0499a.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageTwo: file(relativePath: { eq: "img/_AU_0410.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageThree: file(relativePath: { eq: "img/_AU_0428.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageFour: file(relativePath: { eq: "img/_AU_0916.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bookADemo: file(relativePath: { eq: "img/_AU_0569.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
